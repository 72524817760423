<template>
  <div class="col-12 col-lg-11 col-xl-10 px-0 py-5 mx-auto game-load-block" ref="gameContainer">

    <div
        class="row mx-0 buttons-container justify-content-end justify-content-md-center justify-content-xl-end mb-0 mb-md-2 mb-xl-0"
        v-if="typeof provider !== 'undefined'  && provider.toLowerCase() !== 'wheeloffortune'">
      <a href="#" class="btn enableFullScreen d-none d-md-inline-block float-right border-0" id="fullScreenBtn"
         @click.prevent="screenToggle">
        <i class="fa fa-arrows-alt text-white" v-if="!isFullScreenMode"></i>
        <i class="fa fa-close text-white" v-else></i>
      </a>
      <a href="#" @click.prevent="closeUrl()"
         class="btn close-btn text-white ml-3 mr-2 mr-md-0 float-right border-0">
        <i class="fa fa-close text-white"></i>
      </a>
    </div>

    <div class="row mx-0 pb-5">
      <div class="col-12 col-md-9 mx-auto px-0 game-cross-block">
        <div class="row w-100" v-if="!loaded">
          <Alert :message="response" class="game-load-alert position-absolute text-center"></Alert>
        </div>

        <div class="row mx-0 buttons-container justify-content-end"
             v-if="typeof provider !== 'undefined'  && provider.toLowerCase() === 'wheeloffortune'">
          <a href="#" @click.prevent="closeUrl()" class="btn close-btn text-white mx-0 float-right border-0">
            <i class="fa fa-close"></i>
          </a>
        </div>
        <div class="px-0 game-response-container text-center" id="game-response-container">
          <a href="#" @click.prevent="closeUrl()" class="btn text-white mx-3 mr-0 float-right border-0"
             id="close-btn-full">
            <i class="fa fa-close text-white"></i>
          </a>

          <a href="#" class="btn border-0 px-0 float-right" id="minimize" @click.prevent="screenToggle">
            <i class="fa fa-window-minimize text-white"></i>
          </a>

          <iframe class="gameLaunchIframe"
                  :src="pregameLaunchUrl()"
                  allowfullscreen
                  frameborder="0"
                  marginheight="0"
          >
          </iframe>
        </div>
      </div>
      <div class="col-3 px-0 d-none d-xl-block" id="gameInfoArea"
           v-if="typeof provider !== 'undefined'  &&  provider.toLowerCase() !== 'wheeloffortune'"
      >
        <b-tabs class="game-page-tab game-info-area text-white py-0 px-0">
          <b-tab class="col-12 px-0">
            <div class="games">
              <div class="row mx-0 game cursor-pointer py-2" v-for="(winner, index) in winners"
                   :key="'winner_'+index" v-bind:class="[index % 2 ? '' : 'light-bg']">

                <div class="col-1 px-0 index-area d-table h-100">
                  <div class="d-table-cell vertical-middle text-center">
                    <span class="font-weight-bold ">{{ index + 1 }}</span>
                  </div>
                </div>

                <div class="col-11 h-100 px-0">
                  <div class="row mx-0 h-100">
                    <div class="col-4 h-100 px-0">
                      <img :src="winner.preview" alt="volga game"
                           class="game-img h-auto mh-100 w-100">
                    </div>
                    <div class="col-5 pr-0 d-table h-100">
                      <div class="d-table-cell vertical-middle">
                        <span class="font-weight-bold">{{ winner.login }}</span>
                      </div>
                    </div>
                    <div class="col-3 d-table pr-1 h-100">
                      <div class="d-table-cell vertical-middle text-right">
                        <span class="game-title font-weight-bold"> {{ winner.win }} Руб</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <SuperomaticSelecter v-on:superoNominationSelected="superoNominationSelected" :game="game"
                         :showModal="showSuperomaticModal" ref="superomaticSelecter"></SuperomaticSelecter>
  </div>
</template>

<script>
/*eslint no-debugger: "error"*/
import {scroller} from 'vue-scrollto/src/scrollTo'
import Alert from '@/components/Alert'
import Config from '@/config'
import SuperomaticSelecter from "./SuperomaticSelecter"

const secondScrollTo = scroller()

export default {
  name: 'TheGame',
  props: ['game', 'provider', 'gameName', 'gameId', 'restoring', 'isLive', 'roundId'],
  data() {
    return {
      isLandscape: false,
      response: {status: 'loading'},
      winnersIcon: require('@/../public/images/icons/games/winners.png'),
      gamesIcon: require('@/../public/images/icons/games/game.png'),
      isFullScreenMode: false,
      superomaticNomination: null,
      loaded: false,
      showSuperomaticModal: false,
      gameLaunchMobileResponse: null
    }
  },
  components: {
    Alert,
    SuperomaticSelecter
  },
  watch: {
    isLandscape() {
      this.isLandscape = screen.height < screen.width
    },
    restoring() {
      if (this.restoring) {
        this.$bvModal.hide('HasAliveGame')
      }
    }
  },
  computed: {
    userData() {
      return this.$store.getters['userData']
    },
    games() {
      let allGames = this.$store.getters['games']
      let keys = Object.keys(allGames)
      let rndKey = keys.length * Math.random() << 0
      let topGames = []

      if (typeof allGames[keys[rndKey]] !== 'undefined') {
        topGames = allGames[keys[rndKey]].games.slice(0, 10)
      }
      return topGames
    },
    winners() {
      return this.$store.getters['getWinnersList']
    },
    gameCloseUrl() {
      return Config.baseUrl + '/Api/CloseGame/' + this.provider
    },
    isMobile() {
      return this.$_config.environment.mobile
    },
    settings() {
      return this.$store.getters.getMainSettings;
    }
  },
  methods: {
    pregameLaunchUrl() {

      if (!this.isMobile) {
        return this.gameLaunchUrl()
      } else {
        if (!this.provider || this.provider.toLowerCase() !== 'wheeloffortune') {
          this.gameLaunchMobile()
          if (this.gameLaunchMobileResponse) {
            return this.gameLaunchUrl()
          }
        } else {
          return this.gameLaunchUrl()
        }
      }
    },
    gameLaunchMobile() {
      let url = this.gameLaunchUrl();
      if(url.length > 0) {
        this.axios.get(url).then(({data}) => {
          if (data instanceof Object) {
            if (data.status) {
              console.log(data)
              if (data.status == "ok") {
                window.location.href = data.data.url
              }
            } else {
              this.gameLaunchMobileResponse = data
            }
          } else {
            this.gameLaunchMobileResponse = data
          }
        });
      }
    },

    gameLaunchUrl() {
      if (!this.provider) {
        return ''
      }

      let gameLaunchUrl = [
        Config.baseUrl,
        '/Api/LoadGame/',
        this.provider,
        '/',
        this.gameId ? this.gameId : ''
      ].join('')

      if (typeof this.provider !== 'undefined' && this.provider.toLowerCase() !== 'superomatic' && this.provider.toLowerCase() !== 'superomaticv2') {
        if (typeof this.settings !== "undefined") {
          if (this.roundId) { // && this.settings.hasOwnProperty("freeRoundModules") && this.settings.freeRoundModules
            gameLaunchUrl += '?gamelaunchData%5BroundId%5D=' + this.roundId;
          }
        }
        return gameLaunchUrl
      } else {
        if (!this.superomaticNomination && !this.restoring) {
          this.showSuperomaticModal = true
          return ''
        } else {
          gameLaunchUrl += "?gamelaunchData%5Bnomination%5D=" + this.superomaticNomination
          return gameLaunchUrl
        }
      }
    },
    superoNominationSelected(value) {
      this.superomaticNomination = value
      this.loaded = true
    },
    containerSize() {
      try {
        let availWidth = document.querySelector('.game-cross-block').offsetWidth
        return {
          width: availWidth,
          height: availWidth / 1.8
        }
      } catch (err) {
        console.log(err.message)
      }
    },
    screenToggle() {
      if (document.fullscreenElement) {
        this.closeFullscreen();
      } else {
        this.fullscreen()
      }
    },
    fullscreen() {
      let framesDiv = document.querySelector("#game-response-container");
      if (document.fullscreenEnabled ||
          document.webkitFullscreenEnabled ||
          document.mozFullScreenEnabled ||
          document.msFullscreenEnabled) {
        if (framesDiv.requestFullscreen) {
          framesDiv.requestFullscreen();
        } else if (framesDiv.webkitRequestFullscreen) {
          framesDiv.webkitRequestFullscreen();
        } else if (framesDiv.mozRequestFullScreen) {
          framesDiv.mozRequestFullScreen();
        } else if (framesDiv.msRequestFullscreen) {
          framesDiv.msRequestFullscreen();
        }
        if (framesDiv.webkitExitFullscreen) {
          framesDiv.webkitExitFullscreen()
        }
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    setIframeSizes() {
      let container = document.querySelector('.game-response-container')
      if (!container) {
        return
      }

      let gameLaunchIframe = document.querySelector('.gameLaunchIframe')
      let sizes = {
        width: container.offsetWidth,
        height: container.offsetWidth / 1.4
      }

      if (this.provider === "b2bslots") {
        sizes = {
          width: parseInt(container.offsetWidth),
          height: parseInt(window.innerHeight),
        }
      }

      gameLaunchIframe.style.height = sizes.height + 'px'
      gameLaunchIframe.style.maxHeight = sizes.height + 'px'
      gameLaunchIframe.style.width = sizes.width + 'px'
      gameLaunchIframe.style.maxWidth = 100 + '%'


      if (typeof this.provider !== 'undefined' && this.provider.toLowerCase() !== 'wheeloffortune') {
        document.querySelector('.game-info-area').style.height = sizes.height + 'px'
      }
    },
    setOriantationOnChangeEvent() {
      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.isLandscape = window.innerWidth > window.innerHeight
          this.setIframeSizes()
        }, 20)
      })
    },
    closeUrl() {
      let closeUrl = this.gameCloseUrl
      this.response = null
      this.axios.get(closeUrl)
          .then(({data}) => {
            this.response = data
            if (this.response.status === "ok") {
              setTimeout(() => {
                this.$store.dispatch('checkForAliveGame')
                return this.$router.push({name: 'home'})
              }, 1000)
            }
          }).catch(err => {
        if (err) {
          this.response = {status: 'error', msg: 'Ошибка при закрытии игры. Попробуйте снова'}
          return this.$router.push({name: 'home'})
        }
      })
    }
  },
  mounted() {
    this.setOriantationOnChangeEvent();
    this.setIframeSizes()
    if (document.querySelector('.gameLaunchIframe')) {
      document.querySelector('.gameLaunchIframe').onload = () => {
        this.loaded = true
      }
    }

    if (!this.userData) {
      this.$router.push({name: 'home'})
      return this.$bvModal.show('AuthModal')
    }

    setTimeout(() => {
      secondScrollTo('iframe')
    }, 600)

    let fullScreenBtn = document.querySelector('#fullScreenBtn')
    document.querySelector("#minimize").style.display = 'none'
    document.querySelector("#close-btn-full").style.display = 'none'

    document.addEventListener('webkitfullscreenchange', this.fullscreenChange)
    document.addEventListener('mozfullscreenchange', this.fullscreenChange)
    document.addEventListener('fullscreenchange', this.fullscreenChange)
    document.addEventListener('MSFullscreenChange', this.fullscreenChange)
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        document.querySelector("#minimize").style.display = 'block'
        document.querySelector("#close-btn-full").style.display = 'block'
      } else {
        document.querySelector("#minimize").style.display = 'none'
        document.querySelector("#close-btn-full").style.display = 'none'
      }
    })
    document.getElementById('game-response-container').addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        fullScreenBtn.innerHTML = '<i class="fa fa-window-minimize text-white"></i>'
      } else {
        let frame = document.querySelector('#game-response-container');

        frame.style.height = this.containerSize().height + 'px'
        fullScreenBtn.innerHTML = '<i class="fa fa-arrows-alt text-white"></i>'
      }
    });
  }
}
</script>

<style>

.gameLaunchIframe {
  background-color: #21389b;
}

.winner {
  height: 50px;
  font-size: 14px;
  word-break: break-all;
}

.game-title {
  font-size: 14px;
  word-break: break-all;
}

.enableFullScreen {
  background-color: transparent !important;
  box-shadow: none !important;
}

.buttons-container {
  height: 35px;
}

.game-page-tab .tab-pane {
  padding: 0 !important;
}

.game-page-tab .nav-tabs {
  border: none !important;
  justify-content: center !important;
}

.game-page-tab .nav-link {
  border: none !important;
  padding: 0 !important;
  outline: none !important;
  color: white;
}

.game-page-tab .nav-link.active {
  color: #923278 !important;
}

.game-page-tab .nav-link.active > .tab-title {
  background-color: #0f0518;
}

.game-page-tab .nav-item {
  width: 50%;
}
</style>

<style scoped>
#minimize,
#close-btn-full {
  box-shadow: none !important;
}

.game-name {
  color: #0f0cc9;
}

.tab-title {
  background-color: #1b0c27;
}

.close-btn {
  background-image: linear-gradient(#051949, #0a37a1);
  box-shadow: none !important;
}

.tab-icon {
  height: 30px;
}

.tab-title-area {
  border-top: 1px solid #10131c;
  border-bottom: 1px solid #10131c;
}

.game-info-area .tab-content {
  overflow-y: scroll;
  height: 100%;
  max-height: 100%;
}

.game-info-area::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

.game-info-area::-webkit-scrollbar-thumb {
  background-color: #051949;
  border-radius: 7px;
}

.light-bg {
  background-color: #051949;
}

.game {
  height: 100px;
}

.game-img {
  border-radius: 7px;
  object-fit: contain;
}

.winners-title {
  font-size: 12px;
}

.game-info-area {
  background-color: #0a37a1;
  overflow-x: hidden;
}

.game-load-block {
  min-height: fit-content !important;
  background-repeat: no-repeat;
  height: fit-content;
}

.game-load-block .game-load-alert {
  z-index: 2;
  top: 150px;
  left: 50%;
  width: 70%;
  margin-left: -35%;
  min-height: 200px;
}

.game-load-block .game-load-alert .alert {
  min-height: 200px;
  align-items: center !important;
  justify-content: center;
  display: flex !important;
  padding: 0 !important;
}

#app.mobile .page-content.main-block {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.resp-loader.loading {
  max-width: 300px;
  max-height: 300px;
  top: unset;
  left: unset;
}
</style>